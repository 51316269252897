import styled from '@emotion/styled';

export default styled.div`
  * {
    font-family: Averta;
  }

  .amount {
    font-weight: bold;
  }

  * {
    line-height: 25px;
  }

  .collapse-caret {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-left: 10px;

    :hover {
      transform: scale(1.2);
    }
  }

  .item {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    :nth-last-of-type(1) {
      border-bottom: none;
    }

    .name {
      color: rgba(0, 0, 0, 0.5);
    }

    .price {
    }

    @media (max-width: 450px) {
      width: 85vw;
    }
  }

  .pay-button {
    margin: 20px 0;
    box-shadow: none;
    border: none;
    background: #27ae60;
    border-radius: 3px;
    width: 400px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12.5px;
    padding: 15px 0;

    @media (max-width: 450px) {
      width: 85vw;
    }
  }

  .success-message,
  .expiry-message {
    margin: 20px 0;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    width: 400px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12.5px;
    text-align: center;
    padding: 15px 0;
  }

  .success-message {
    color: #27ae60;
  }

  .expiry-message {
    color: #ff0000;
  }

  .payment-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;

    img {
      height: 60px;
    }
  }

  .section-beneficiary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    margin: 15px 0;
    border: 1px dashed #3525e6;
    background-color: #eaeaf3;
    border-radius: 10px;
    width: 400px;

    .bank-name {
      font-weight: 500;
      margin-bottom: 10px;
    }

    .account-number {
      font-weight: 600;
      font-size: 24px;
      color: #3525e6;
    }
    .account-name {
      font-weight: 400;
      color: #3525e6;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .instructions {
      font-size: 14px;
      color: #828282;
      text-align: center;
    }
  }

  .section-protection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    min-height: 300px;
    background-color: rgba(224, 224, 224, 0.25);
    border-radius: 5px;
    padding: 20px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;

    * {
      text-align: justify;
      @media (max-width: 450px) {
        text-align: center;
      }
    }

    img {
      height: 60px;
    }

    .title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      margin: 10px 0;
    }

    .more {
      margin-top: 20px;
      color: #2f80ed;
      cursor: pointer;
      font-size: 14px;
    }

    @media (max-width: 450px) {
      width: 85vw;
    }
  }

  .section-links {
    display: flex;
    flex-direction: row;
    padding: 35px 0;

    * {
      margin: 0 20px;
      color: #2f80ed;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      padding: 20px 0;

      * {
        margin: 10px 0;
      }
    }
  }
`;
