import { Avatar, Box, Spinner, Heading } from '@chakra-ui/core';
import Wrapper from './index.styles';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icon, Collapse } from '@chakra-ui/core';
import { PaystackButton } from 'react-paystack';
import Axios from 'axios';
import { format_currency } from 'util/helpers';

const PayView = () => {
  const [loading, set_loading] = useState(false);
  const [paylink, set_paylink] = useState(null);
  const [collapsed, set_collapsed] = useState(false);
  const [success, set_success] = useState(null);
  const [expired, set_expired] = useState(null);
  const [cancelled, set_cancelled] = useState(null);

  const router = useRouter();

  const fetchPaymentLink = async code => {
    set_loading(true);
    try {
      const {
        data: { data },
      } = await Axios({
        url: `/payment_request/${code}`,
        method: 'GET',
        headers: {},
      });
      set_paylink(data);
      if (data['paid']) set_success(true);
      else if (data['expired']) set_expired(true);
      else if (data['cancelled']) set_cancelled(true);
    } catch (e) {
      set_success(false);
    }
    set_loading(false);
  };

  useEffect(() => {
    const code = router.asPath.replace('/?code=', '');
    fetchPaymentLink(code);
  }, []);

  const paystack_config = {
    publicKey: process.env.PAYSTACK_KEY_PUBLIC,
    email: 'visitor@simpu.co',
    amount: paylink ? paylink.amount : 0,
    metadata: {
      purpose: 'payment-request',
      code: paylink && paylink.code,
    },
    onSuccess: () => set_success(true),
  };

  return (
    <Wrapper>
      <Box display="flex">
        <img className="logo-simpu" src="/img/logo.svg" />
      </Box>

      {loading && (
        <div className="content-body">
          <Spinner thickness="4px" margin="auto" emptyColor="gray.200" color="blue.500" size="xl" />
        </div>
      )}
      {paylink && (
        <div className="content-body">
          <Avatar className="logo-org" src={paylink.organization.image} />

          <Box textAlign="center">
            {paylink.organization.name} {!success ? 'is requesting ' : 'requested '}
            <span className="amount">₦ {format_currency(paylink.amount / 100)}</span>
          </Box>
          <div>
            for {paylink.items.length} items(s)
            <Icon
              className="collapse-caret"
              name={`triangle-${collapsed ? 'up' : 'down'}`}
              onClick={() => set_collapsed(!collapsed)}
              size="12px"
            />
          </div>
          <Collapse isOpen={!collapsed} marginY="30px">
            <div>
              {paylink.items.map(i => (
                <div className="item" key={i.id}>
                  <div className="name">{`${i.name} (x${i.quantity})`}</div>
                  <div className="price">₦ {format_currency(i.amount_total / 100)}</div>
                </div>
              ))}
            </div>
          </Collapse>
          {!success && !expired && !cancelled && (
            <>
              {paylink.provider === 'paystack' && (
                <>
                  <PaystackButton
                    className="pay-button"
                    {...paystack_config}
                    text={`Pay ${paylink.organization.name} ₦ ${format_currency(
                      paylink.amount / 100,
                    )}`}
                  />
                  <div>All payment options available</div>
                  <div className="payment-options">
                    <img src="/img/mastercard.png" />
                    <img src="/img/visa.png" />
                  </div>
                </>
              )}
              {paylink.provider === 'onepipe' && (
                <Box className="section-beneficiary">
                  <Box className="bank-name">{paylink['managed_account']['bank_name']}</Box>
                  <Box className="account-number">
                    {paylink['managed_account']['account_number']}
                  </Box>
                  <Box className="account-name">{paylink['managed_account']['account_name']}</Box>

                  <Box className="instructions">
                    Please send the exact total amount to the account number above. Once the
                    merchant receives this payment, you'll also be notified on confirmation
                  </Box>
                </Box>
              )}
            </>
          )}

          {success && <div className="success-message">Payment completed successfully</div>}
          {expired && <div className="expiry-message">Payment link expired</div>}
          {cancelled && <div className="expiry-message">Payment link cancelled</div>}

          <div className="section-protection">
            <img src="/img/shield-check.svg" />
            <div className="title">Simpu Buyer Protection</div>
            <div className="paragraph">
              Simpu protects your purchase by delaying payments to the merchant for 3 days. In case
              you may have complaints.
            </div>
            <a href="https://app.simpu.co/terms-conditions" target="_blank" className="more">
              Learn more about this
            </a>
          </div>

          <div className="section-links">
            <a href="https://app.simpu.co/terms-conditions" target="_blank">
              Privacy Policy
            </a>
            <a href="https://app.simpu.co/terms-conditions" target="_blank">
              Terms of Use
            </a>
            <a href="https://simpu.co" target="_blank">
              About Simpu
            </a>
          </div>
        </div>
      )}
      {success === false && (
        <Box className="content-body">
          <Icon name="warning-2" size="100px" color="red.500" />
          <Heading size="md" marginY="20px">
            Looks like you've got an invalid payment link
          </Heading>
          <div>Please reload, or try again</div>
        </Box>
      )}
    </Wrapper>
  );
};

export default PayView;
