import Wrapper from './index.styles';
import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/core';

const Layout = ({ children }) => {
  const [img_index, set_img_index] = useState(1);

  useEffect(() => {
    set_img_index(Math.floor(Math.random() * 5) + 1);
  }, []);

  return (
    <Wrapper>
      <Box backgroundImage={`url('/img/hero-${img_index}.svg')`} className="hero-img" />
      <div className="content">{children}</div>
    </Wrapper>
  );
};

export default Layout;
