import styled from '@emotion/styled';

export default styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;

  .hero-img {
    height: 100vh;
    width: 40vw;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 990px) {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 60vw;

    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #ccc;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #999;
    }

    @media (max-width: 990px) {
      width: 100vw;
    }

    .logo-simpu {
      height: 30px;
      margin: 30px auto;
      width: auto;
    }

    .logo-org {
      height: 60px;
      width: 60px;
      margin: 30px 0;

      @media (max-width: 450px) {
        height: 70px;
        width: 70px;
        margin: 20px 0;
      }
    }

    .content-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: max-content;
      width: 100%;

      @media (max-width: 450px) {
        height: auto;
      }
    }
  }
`;
